export const animate = {
    SectionHeaders: {
        event: "in-view",
        delay: 2,
        duration: 0.5
    },
    AboutMe: {
        event: "in-view",
        duration: 0.5,
        stagger: 0.2
    },
    Contact: {
        event: "in-view",
        duration: 0.5,
        stagger: 0.2
    },
    Work: {
        event: "in-view",
        duration: 0.5

    },
    SVGGraphicCrispin: {
        disableBrowsers: ['Firefox'],
        amount: -40,
        speed: 3,
        triggerID: 'parralax_layer_1'
    },
    SVGGraphicAnderson: {
        disableBrowsers: ['Firefox'],
        amount: 60,
        speed: 3,
        triggerID: 'parralax_layer_1'
    },
    parallaxLayer1: {
        disableBrowsers: ['Firefox'],
        amount: 24,
        speed: 2,
        axis: 'y'
    },
    parallaxLayer2: {
        disableBrowsers: ['Firefox'],
        amount: -12,
        speed: 2.5,
        axis: 'y'
    },
    parallaxLayer3: {
        disableBrowsers: ['Firefox'],
        amount: 8,
        speed: 3,
        axis: 'y'
    },

}

