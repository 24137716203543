import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useParallax } from './AnimateParralax.controller';


export const AnimateParallax = (props) => {



    const animateRef = useRef();
    const selectedChild = Array.isArray(props.children) ? props.children.filter((child) => React.isValidElement(child))[0] : props.children;

    const InnerChild = React.cloneElement(
        selectedChild,
        { animateRef }
    );

    useParallax({ ...props, animateRef })


    return InnerChild
}

AnimateParallax.propTypes = {
    elemID: PropTypes.string,
    options: PropTypes.exact({
        amount: PropTypes.number,
        speed: PropTypes.number,
        axis: PropTypes.oneOf(['x', 'y']),
        triggerID: PropTypes.string,
        disableBrowsers: PropTypes.array
    })
}

