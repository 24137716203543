import React from 'react';

export const SVGHeaderGraphicCrispin = (props) => {

    return (<g ref={props.animateRef} id="header_graphic_crispin">
        <g >
            <path fill="none" stroke="#F2665F" strokeWidth="3" strokeMiterlimit="10" d="M430.4,269.8c0-45.1,30.8-77.4,72.3-77.4
        c23,0,42.3,9.4,54.8,26.5l-19.8,20.5c-8.9-11.5-20.2-17.5-33.5-17.5c-24.9,0-42.6,19.7-42.6,47.9s17.7,47.9,42.6,47.9
        c13.3,0,24.6-6,33.5-17.8l19.8,20.5c-12.6,17.3-31.8,26.7-55,26.7C461.3,347.2,430.4,314.9,430.4,269.8z"/>
            <path fill="none" stroke="#F2665F" strokeWidth="3" strokeMiterlimit="10" d="M702.8,344.6l-25.7-41.7h-1.5h-26.8v41.7H618
        V194.9h57.7c35.6,0,57.9,20.7,57.9,54.3c0,22.5-10.1,38.9-27.4,47.3l29.9,48.1H702.8z M673.9,223.2h-25.1v52.2h25.1
        c18.8,0,28.4-9.8,28.4-26.1C702.2,232.8,692.7,223.2,673.9,223.2z"/>
            <path fill="none" stroke="#F2665F" strokeWidth="3" strokeMiterlimit="10" d="M797.4,194.9h30.8v149.7h-30.8V194.9z" />
            <path fill="none" stroke="#F2665F" strokeWidth="3" strokeMiterlimit="10" d="M889,329.9l10.5-26.1c11.2,9.2,28,15.6,44,15.6
        c18.3,0,25.7-6.8,25.7-16c0-28-77.5-8.8-77.5-64.4c0-25.5,18.3-46.6,56.2-46.6c16.8,0,33.9,4.5,46.3,13.3l-9.5,26.3
        c-12.4-7.9-25.1-11.8-36.9-11.8c-18.3,0-25.3,7.7-25.3,17.1c0,27.6,77.5,8.6,77.5,63.5c0,25-18.5,46.4-56.5,46.4
        C922.1,347.2,900.8,340.2,889,329.9z"/>
            <path fill="none" stroke="#F2665F" strokeWidth="3" strokeMiterlimit="10" d="M1176,249.3c0,33.4-22.3,54.1-57.9,54.1h-26.8
        v41.3h-30.8V194.9h57.7C1153.7,194.9,1176,215.7,1176,249.3z M1144.8,249.3c0-16.5-9.5-26.1-28.4-26.1h-25.1v52h25.1
        C1135.3,275.1,1144.8,265.5,1144.8,249.3z"/>
            <path fill="none" stroke="#F2665F" strokeWidth="3" strokeMiterlimit="10" d="M1237.5,194.9h30.8v149.7h-30.8V194.9z" />
            <path fill="none" stroke="#F2665F" strokeWidth="3" strokeMiterlimit="10" d="M1461.7,194.9v149.7h-25.3l-66.4-90.9v90.9h-30.5
        V194.9h25.5l66.2,90.9v-90.9H1461.7z"/>
        </g>
    </g>

    )

}