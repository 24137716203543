import React from 'react';
import './WorkProject.css';

export const WorkProject = (props) => {

    const xPos = props.active ? 0 : props.setLeft ? -200 : 200;
    const scale = props.active ? 1 : 0.95;

    return (
        <div
            className={`project-wrapper ${props.active ? '' : 'project-blur'}`}
            onClick={props.onClick}
            style={{
                transition: 'filter 1s, transform 1s',
                transform: `matrix(${scale}, 0, 0, ${scale}, ${xPos}, 0)`,
                filter: `blur(${props.active ? '0px' : '3px'})`,
                zIndex: props.active ? 1 : 0
            }}
        >
            <div className='project-inner-wrapper'>
                <div className='project-image inset-shadow' style={{ backgroundImage: `url('${props.imageSrc}')` }} />
                <h2 className='project-title' aria-label='project title'> {props.title.toUpperCase()} </h2>
                <sub className='project-role' aria-label='project title'> {props.role} </sub>
                <h4 className='project-subtitle' aria-label='project title'>{props.subTitle} </h4>
                <div className='project-stack' aria-label='project tech stack'>{props.stack} </div>
                <span className='project-description' aria-label='project description'>
                    {props.description}
                </span>
                <a className='project-link' href={props.link} target="_blank" rel="noopener noreferrer">{props.linkText}</a>
            </div>
        </div>
    )
}