import React from 'react';

export const WorkHeader = (props) => {
    return (
        <div id='work_header' ref={props.animateRef} >
            <div className='section-header left'>
                <svg id='work_header' ref={props.animateRef} viewBox='0 0 295 100.3' height={'100%'}>
                    <text
                        transform="matrix(0.89 0 0 1 1.5001 75.6192)"
                        fill="none"
                        stroke="#F7D789"
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        fontFamily="Montserrat"
                        fontWeight="bold"
                        fontSize={87.2}
                        letterSpacing={5.6}>WORK</text>
                </svg>
                <div className='section-header-line yellow right' ></div>
            </div>
        </div>

    )
}