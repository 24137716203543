
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const defaults = {
    amount: 40,
    speed: 1,
    direction: 'horizontal',
    axis: 'x'
}

export const useParallax = (props) => {

    const options = { ...defaults, ...props.options };
    const { amount, speed, axis, triggerID, disableBrowsers } = options;


    useEffect(() => {

        const elem = props.animateRef.current;
        const trigger = triggerID ? '#' + triggerID : elem;
        const browser = window.navigator.userAgent.split(' ').slice(-1)[0].split('/')[0];
        const disabled = disableBrowsers && disableBrowsers.includes(browser) ? true : false;

        if (!disabled) {
            gsap.to(elem, {
                [axis]: amount * 100,
                scrollTrigger: {
                    trigger: trigger,
                    scrub: speed
                }
            })
        }





    }, [props.animateRef, amount, speed, axis, triggerID, disableBrowsers])
}

