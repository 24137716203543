import React from 'react';
import './Arrow.css';

export const Arrow = (props) => {

    const click = props.onClick ? props.onClick : () => { };
    const className = props.className ? "center-con " + props.className : "center-con";
    const opacity = typeof props.visible !== 'boolean' ? 1 : props.visible ? 1 : 0;
    const pointerEvents = opacity ? 'auto' : 'none';

    return (
        <div className={className} style={{ transform: `rotate(${props.rotate || 0}deg)`, opacity, pointerEvents }} onClick={click}>
            <div className="round">
                <div id="cta">
                    <span className="arrow first next "></span>
                    <span className="arrow second next "></span>
                </div>
            </div>
        </div>
    )
}