import React, { useRef, useEffect, useState } from "react";
import './Menu.css';
import { MenuMarker } from "../MenuMarker/MenuMarker";
import { menuConfig } from '../../config/Menu.config'
import { AnchorLink } from "../AnchorLink/AnchorLink";
import { useDynamicGradient } from "../../utils/useDynamicGradient";



export const Menu = (props) => {

    const ref = useRef(null);
    const [menubarRef, setmenubarRef] = useState(null);

    useEffect(() => {
        setmenubarRef(ref.current)
    }, [menubarRef])

    useDynamicGradient({ elem: menubarRef })

    return (
        <>
            <div ref={ref} id='menubar' className='menubar'>
                <MenuMarker menuConfig={menuConfig} />

                <div className='site-title'>CRISPIN ANDERSON</div>
                <div className='menu-wrapper' style={{ gridTemplateColumns: `repeat(${menuConfig.length}, auto)` }}>
                    {menuConfig.map((menuItem, i) =>
                        <div
                            key={menuItem.menuElem + i}
                            id={menuItem.menuElem}
                            className="menu-item"
                        >
                            <AnchorLink href={menuItem.anchor} text={menuItem.menuText} offset={menuItem.offset} />
                        </div>
                    )}
                </div>
            </div>

        </>
    )
};