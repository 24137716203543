import React from 'react';

export const SVGParralaxLayer3 = (props) => {

    return (
        <g ref={props.animateRef} id="parallax_layer_3">
            <rect x="68" y="57.3" fill="none" stroke="#364B67" strokeMiterlimit="10" width="591" height="591" />
            <rect x="978" y="1871" fill="none" stroke="#364B67" strokeMiterlimit="10" width="591" height="591" />
            <rect x="92" y="2560" fill="none" stroke="#364B67" strokeMiterlimit="10" width="591" height="591" />
            <rect x="934" y="4351" fill="none" stroke="#364B67" strokeMiterlimit="10" width="591" height="591" />
            <rect x="-248" y="3988.1" fill="none" stroke="#364B67" strokeMiterlimit="10" width="591" height="591" />
            <rect x="662" y="5801.9" fill="none" stroke="#364B67" strokeMiterlimit="10" width="591" height="591" />
            <rect x="-224" y="6490.9" fill="none" stroke="#364B67" strokeMiterlimit="10" width="591" height="591" />
            <rect x="618" y="8281.8" fill="none" stroke="#364B67" strokeMiterlimit="10" width="591" height="591" />
        </g>
    )
}