export const workConfig = [
    {
        imageSrc: '/public/images/dams.jpg',
        title: 'Skovde Art Museum',
        subTitle: "A digital art musuem.",
        role: 'Creative lead & front end developer',
        stack: 'VanillaJS . GSAP',
        description: `An experimental web gallery for Skovde art musem. The breif, we need a web site but we dont want a web site....
        Using a custom SVG based templating system, the site is built around a dynamic grid system which creates an interactive maze. The final product became a kind of alternative CMS. The system allows the museum to show text, interactive (web based) works, images, videos and audio placed around a curated museum maze. Designed to get lost in so you can find something new!`,
        link: "http://skovdeartmuseum.com",
        linkText: "skovdeartmuseum.com"
    },
    {
        imageSrc: '/public/images/sdx.png',
        title: 'Secure Digital Exchange',
        role: 'Software Developer',
        subTitle: "Developing software solutions for secure document signing and delivery",
        stack: 'Typescript MithrilJS Tachyons Bss',
        description: `Part of the SDX team delivering essential business solutions for secure document delivery and signing`,
        link: "https://sdxmessaging.com",
        linkText: "https://sdxmessaging.com"
    },
    {
        imageSrc: '/public/images/bookings.jpg',
        title: 'Ljudteknikerna.se',
        role: 'Design & Full stack development',
        subTitle: "A booking and staff management system.",
        stack: 'NodeJS . Express . Twilio . Postman . PassportJS . Postgresql . React . Material UI',
        description: `A full administration, booking and contact system for this supplier of event technicians. 
        The system provides an multi-user live updating booking system, automatic technician contacting and reply system via email and sms, multi status job flow, job financial reporting and tailored user functionality for super-admins, admins, techicians and customers behind a secure authentication layer.`,
        link: "https://demo.crispinanderson.com",
        linkText: "demo.crispinanderson.com"
    },
    {
        imageSrc: '/public/images/WebBuilder.jpg',
        title: 'SVG Web Builder',
        role: 'Full stack developer',
        subTitle: "SVG based Web design system",
        stack: 'Node. Express . Postgresql . React . Custom Hooks . SVG Importer. Material UI . Custom Hooks',
        description: `A website builder based around the magic of Scalable Vector Graphics. Design -upload - animate -embed content - download and deploy!
        This is a personal ongoing project which enables a user to create a graphical design in any SVG based editor and use this as a template for simplifying the process of designing and creating simple web sites. Parts of the system where used to build this site!`,
        link: "https://svgwebbuilder.com",
        linkText: "Coming soon..."
    }
]