import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useFade } from './AnimateFade.controller';




export const AnimateFade = (props) => {

    const animateRef = useRef();
    const selectedChild = Array.isArray(props.children) ? props.children.filter((child) => React.isValidElement(child))[0] : props.children;

    const InnerChild = React.cloneElement(
        selectedChild,
        { animateRef }
    );

    useFade({ ...props, animateRef })

    return InnerChild
}

AnimateFade.propTypes = {
    options: PropTypes.exact({
        duration: PropTypes.number,
        ease: PropTypes.string,
        from: PropTypes.number,
        to: PropTypes.number,
        delay: PropTypes.number,
        event: PropTypes.string,
        triggerElem: PropTypes.instanceOf(Element),
        offset: PropTypes.number,
        stagger: PropTypes.number
    })
}


