import React from 'react';
import './AboutMe.css';

export const AboutMe = (props) => {

    return (
        <div className='about-wrapper'>
            <div id='about_me_text' ref={props.animateRef} className='about-me-text'>
                <h1> FULL STACK JAVASCRIPT DEVELOPER </h1>
                <p style={{ fontWeight: 100 }} > • JAVASCRIPT • REACT • NODEJS • EXPRESS • POSTGRESQL • HTML5 • CSS • SVG • </p>
                <p> A breif history.</p>
                <p> </p>
                <p> I have always been passionate about computers ever since I was 9 and we got our first home computer, a BBC micro computer (not so micro!)</p>
                <blockquote> I learned to program a few simple things and my fascination never let up from that moment forward. </blockquote>
                <p>  At university I became a Sonic Arts Bsc. Yes, a science degree in the arts is unusual but so was the course. A mixture of sound, music, electronics and programming. I go my first taste of Object Oriented Programming....</p>
                <p> </p>
                <p> The next few years I spent building flash sites and travelling the UK with a touring theatre company as computer and sound technician. I stopped working in theatre, moved to Sweden, took a hiatus from programming for a few years, ran a music festival, toured the world with Swedish group Little Dragon.....</p>
                <p></p>
                <p> With all the varied work I have done over the years, from sound, video, photography, music, graphic design, computer tech and web design </p>
                <p></p>
                <blockquote>I have always considered myself to be a creative technician</blockquote>
                <p> - using my creative skills and technical knowhow to interperet an idea and creatively find solutions to make it reality -</p>
                <p>So back in 2016 I accidentally became the creative lead for a project with Skovde Art musuem. I was initially there to stear the creative direction of the project, it was never my intention to get my hands dirty with the coding but we couldn't find a developer willing or able to take on the job...so i taught myself javascript, fell in love with programming again and the rest is history....</p>

            </div>
        </div>
    )
}