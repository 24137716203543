import React, { useState } from "react";
import './MenuMarker.css';
import { useScrollTriggers } from "./MenuMarker.controller";




export const MenuMarker = (props) => {


    const [markerPosition, setMarkerPosition] = useState({});

    useScrollTriggers({ ...props, onNewMarkerPosition: (pos) => setMarkerPosition(pos) });

    return (
        <div id='menu-marker' className="menu-marker" style={markerPosition}></div>
    )
};