import React, { useState } from 'react';
import './Work.css';
import { WorkProject } from './WorkProject';
import { workConfig } from '../../../config/Work.config';
import { Arrow } from './Arrow';

export const Work = (props) => {

    const [activeItem, setActiveItem] = useState(1);

    return (
        <div ref={props.animateRef} className='work-wrapper'>
            <Arrow visible={activeItem !== 0} rotate={180} onClick={() => setActiveItem(activeItem - 1)} className='carousel-btn carousel-btn-left' />

            {workConfig.map((item, i) =>
                <WorkProject
                    {...item}
                    key={'WorkProject_' + i}
                    active={i === activeItem}
                    setLeft={i < activeItem}
                    onClick={() => setActiveItem(i)}
                />)
            }

            <Arrow visible={activeItem !== workConfig.length - 1} onClick={() => setActiveItem(activeItem + 1)} className='carousel-btn carousel-btn-right' />
        </div>
    )
}