import React from "react";
import { SVGHeaderGraphicCrispin } from "./SVGHeaderGraphic";
import { SVGHeaderGraphicAnderson } from "./SVGHeaderGraphicAnderson";
import { AnimateParallax } from "../Animate/AnimateParralax";
import { animate } from '../../config/Animate.config';
import { SVGParralaxLayer1 } from "./SVGParallaxLayer1";
import { SVGParralaxLayer2 } from "./SVGParallaxLayer2";
import { SVGParralaxLayer3 } from "./SVGParallaxLayer3";


export const SVGBackground = (props) => {

    return (

        <svg ref={props.animateRef} id="svg_master" x="0px" y="0px" viewBox="0 0 1366 10000">
            <g id="header">
                <g id="header_circles">
                    <circle fill="none" stroke="#364B67" strokeMiterlimit="10" cx="378.5" cy="447.9" r="869.6" />
                    <circle fill="none" stroke="#364B67" strokeMiterlimit="10" cx="352.5" cy="359" r="869.6" />
                    <circle fill="none" stroke="#364B67" strokeMiterlimit="10" cx="326.4" cy="270.2" r="869.6" />
                </g>

                <AnimateParallax options={animate.SVGGraphicCrispin}> <SVGHeaderGraphicCrispin /></AnimateParallax>
                <AnimateParallax options={animate.SVGGraphicAnderson}> <SVGHeaderGraphicAnderson /> </AnimateParallax>
                <AnimateParallax options={animate.parallaxLayer1}><SVGParralaxLayer1 /> </AnimateParallax>
                <AnimateParallax options={animate.parallaxLayer2}><SVGParralaxLayer2 /> </AnimateParallax>
                <AnimateParallax options={animate.parallaxLayer3}><SVGParralaxLayer3 /> </AnimateParallax>

            </g>
        </svg>



    );
}
