import React from 'react';

export const SVGHeaderGraphicAnderson = (props) => {
    return (
        <g ref={props.animateRef} id="header_graphic_anderson">
            <g >
                <path fill="none" stroke="#F7D789" strokeWidth="3" strokeMiterlimit="10" d="M-369.2,399.7h-61.4l-11.7,31.8h-31.4l58.9-148.5 h30.2l59.1,148.5h-32.1L-369.2,399.7z M-378.9,373.6l-21-56.9l-21,56.9H-378.9z" />
                <path fill="none" stroke="#F7D789" strokeWidth="3" strokeMiterlimit="10" d="M-169.1,283v148.5h-25.1l-65.9-90.2v90.2h-30.2
                V283h25.3l65.7,90.2V283H-169.1z"/>
                <path fill="none" stroke="#F7D789" strokeWidth="3" strokeMiterlimit="10" d="M-116.4,283h60.1c43.2,0,72.9,29.3,72.9,74.3
                s-29.7,74.3-72.9,74.3h-60.1V283z M-57.8,403.3c26.3,0,43.4-17.6,43.4-46s-17.2-46-43.4-46h-28v92.1h28
                C-57.8,403.4-57.8,403.3-57.8,403.3z"/>
                <path fill="none" stroke="#F7D789" strokeWidth="3" strokeMiterlimit="10" d="M163.3,404v27.6H61V283h99.9v27.6H91.4v32.3h61.4
                v26.7H91.4V404H163.3z"/>
                <path fill="none" stroke="#F7D789" strokeWidth="3" strokeMiterlimit="10" d="M293.3,431.5l-25.5-41.4h-1.5h-26.6v41.4H209V283
                h57.2c35.3,0,57.4,20.6,57.4,53.9c0,22.3-10,38.6-27.2,46.9l29.7,47.7H293.3z M264.6,311h-24.9v51.8h24.9
                c18.7,0,28.1-9.8,28.1-25.9C292.7,320.6,283.3,311,264.6,311z"/>
                <path fill="none" stroke="#F7D789" strokeWidth="3" strokeMiterlimit="10" d="M358.8,416.9l10.4-25.9
                c11.1,9.1,27.8,15.5,43.6,15.5c18.1,0,25.5-6.8,25.5-15.9c0-27.8-76.9-8.7-76.9-63.9c0-25.3,18.1-46.3,55.7-46.3
                c16.6,0,33.6,4.5,45.9,13.2l-9.4,26.1c-12.3-7.9-24.9-11.7-36.6-11.7c-18.1,0-25.1,7.6-25.1,17c0,27.4,76.9,8.5,76.9,63
                c0,24.8-18.3,46-56.1,46C391.7,434.1,370.5,427.1,358.8,416.9z"/>
                <path fill="none" stroke="#F7D789" strokeWidth="3" strokeMiterlimit="10" d="M502.7,357.3c0-44.1,30.6-76.8,72.3-76.8
                c41.5,0,72.3,32.5,72.3,76.8s-30.8,76.8-72.3,76.8C533.3,434.1,502.7,401.4,502.7,357.3z M616.4,357.3c0-28.2-17.8-47.5-41.4-47.5
                c-23.6,0-41.4,19.3-41.4,47.5s17.8,47.5,41.4,47.5C598.6,404.8,616.4,385.5,616.4,357.3z"/>
                <path fill="none" stroke="#F7D789" strokeWidth="3" strokeMiterlimit="10" d="M813,283v148.5h-25.1L722,341.4v90.2h-30.2V283
                H717l65.7,90.2V283H813z"/>
            </g>
            <g >
                <path fill="#4D7199" d="M56.3,446.7l0.7-0.8c0.5,0.7,1.1,1,1.8,1c1,0,1.5-0.6,1.5-1.7v-4.8h-3.1v-1.1h4.3v5.7
                c0,1.9-0.9,2.8-2.6,2.8C57.8,447.9,56.9,447.5,56.3,446.7z"/>
                <path fill="#4D7199" d="M69.1,445.7h-4.5l-0.9,2.1h-1.2l3.8-8.5h1.2l3.8,8.5H70L69.1,445.7z M68.7,444.8l-1.8-4.2l-1.8,4.2H68.7z"
                />
                <path fill="#4D7199" d="M79.5,439.4l-3.7,8.5h-1.2l-3.7-8.5h1.3l3,7l3.1-7H79.5z" />
                <path fill="#4D7199" d="M85.7,445.7h-4.5l-0.9,2.1H79l3.8-8.5H84l3.8,8.5h-1.3L85.7,445.7z M85.3,444.8l-1.8-4.2l-1.8,4.2H85.3z"
                />
                <path fill="#4D7199" d="M88.4,446.9l0.4-0.9c0.6,0.6,1.7,1,2.8,1c1.5,0,2.1-0.6,2.1-1.3c0-2.1-5.1-0.8-5.1-3.9
                c0-1.3,1-2.4,3.2-2.4c1,0,2,0.3,2.7,0.8l-0.4,1c-0.7-0.5-1.6-0.7-2.3-0.7c-1.4,0-2.1,0.6-2.1,1.3c0,2.1,5.1,0.8,5.1,3.9
                c0,1.3-1,2.4-3.3,2.4C90.3,447.9,89.1,447.5,88.4,446.9z"/>
                <path fill="#4D7199" d="M96,443.6c0-2.5,1.9-4.3,4.5-4.3c1.3,0,2.4,0.4,3.2,1.3l-0.8,0.8c-0.7-0.7-1.5-1-2.4-1
                c-1.9,0-3.3,1.4-3.3,3.3s1.4,3.3,3.3,3.3c0.9,0,1.7-0.3,2.4-1l0.8,0.8c-0.8,0.9-1.9,1.3-3.2,1.3C97.9,447.9,96,446.1,96,443.6z"/>
                <path fill="#4D7199" d="M111.1,447.8l-1.8-2.6c-0.2,0-0.4,0-0.5,0h-2.1v2.6h-1.2v-8.5h3.3c2.2,0,3.5,1.1,3.5,3
                c0,1.3-0.7,2.3-1.9,2.7l2,2.8H111.1z M111,442.3c0-1.2-0.8-1.9-2.4-1.9h-2.1v3.8h2.1C110.2,444.2,111,443.5,111,442.3z"/>
                <path fill="#4D7199" d="M114.2,439.4h1.2v8.5h-1.2V439.4z" />
                <path fill="#4D7199" d="M124.8,442.3c0,1.8-1.3,3-3.5,3h-2.1v2.6H118v-8.5h3.3C123.5,439.4,124.8,440.5,124.8,442.3z M123.6,442.3
                c0-1.2-0.8-1.9-2.4-1.9h-2.1v3.8h2.1C122.8,444.2,123.6,443.5,123.6,442.3z"/>
                <path fill="#4D7199" d="M128,440.4h-2.9v-1.1h7v1.1h-2.9v7.4H128V440.4z" />
                <path fill="#4D7199" d="M136.1,444h3.3v1h-3.3V444z" />
                <path fill="#4D7199" d="M150.2,447.8l-1.8-2.6c-0.2,0-0.4,0-0.5,0h-2.1v2.6h-1.2v-8.5h3.3c2.2,0,3.5,1.1,3.5,3
                c0,1.3-0.7,2.3-1.9,2.7l2,2.8H150.2z M150.2,442.3c0-1.2-0.8-1.9-2.4-1.9h-2.1v3.8h2.1C149.4,444.2,150.2,443.5,150.2,442.3z"/>
                <path fill="#4D7199" d="M159.5,446.8v1.1h-6.1v-8.5h6v1.1h-4.8v2.6h4.2v1h-4.2v2.7L159.5,446.8L159.5,446.8z" />
                <path fill="#4D7199" d="M166.9,445.7h-4.5l-0.9,2.1h-1.2l3.8-8.5h1.2l3.8,8.5h-1.3L166.9,445.7z M166.4,444.8l-1.8-4.2l-1.8,4.2
                H166.4z"/>
                <path fill="#4D7199" d="M169.5,443.6c0-2.5,1.9-4.3,4.5-4.3c1.3,0,2.4,0.4,3.2,1.3l-0.8,0.8c-0.7-0.7-1.5-1-2.4-1
                c-1.9,0-3.3,1.4-3.3,3.3s1.4,3.3,3.3,3.3c0.9,0,1.7-0.3,2.4-1l0.8,0.8c-0.8,0.9-1.9,1.3-3.2,1.3
                C171.4,447.9,169.5,446.1,169.5,443.6z"/>
                <path fill="#4D7199" d="M180.5,440.4h-2.9v-1.1h7v1.1h-2.9v7.4h-1.2L180.5,440.4L180.5,440.4z" />
                <path fill="#4D7199" d="M188.6,444h3.3v1h-3.3V444z" />
                <path fill="#4D7199" d="M204.4,439.4v8.5h-1l-5.1-6.3v6.3h-1.2v-8.5h1l5.1,6.3v-6.3H204.4z" />
                <path fill="#4D7199" d="M206.2,443.6c0-2.5,1.9-4.3,4.5-4.3s4.5,1.8,4.5,4.3s-1.9,4.3-4.5,4.3
                C208.1,447.9,206.2,446.1,206.2,443.6z M214,443.6c0-1.9-1.4-3.3-3.3-3.3c-1.9,0-3.3,1.4-3.3,3.3s1.4,3.3,3.3,3.3
                S214,445.5,214,443.6z"/>
                <path fill="#4D7199" d="M217.1,439.4h3.6c2.7,0,4.6,1.7,4.6,4.2s-1.9,4.2-4.6,4.2h-3.6V439.4z M220.6,446.8c2.1,0,3.4-1.3,3.4-3.2
                c0-1.9-1.3-3.2-3.4-3.2h-2.3v6.4L220.6,446.8L220.6,446.8z"/>
                <path fill="#4D7199" d="M233.2,446.8v1.1h-6.1v-8.5h6v1.1h-4.8v2.6h4.2v1h-4.2v2.7L233.2,446.8L233.2,446.8z" />
                <path fill="#4D7199" d="M233.8,446.7l0.7-0.8c0.5,0.7,1.1,1,1.8,1c1,0,1.5-0.6,1.5-1.7v-4.8h-3.1v-1.1h4.3v5.7
                c0,1.9-0.9,2.8-2.6,2.8C235.2,447.9,234.3,447.5,233.8,446.7z"/>
                <path fill="#4D7199" d="M240.6,446.9l0.4-0.9c0.6,0.6,1.7,1,2.8,1c1.5,0,2.1-0.6,2.1-1.3c0-2.1-5.1-0.8-5.1-3.9
                c0-1.3,1-2.4,3.2-2.4c1,0,2,0.3,2.7,0.8l-0.4,1c-0.7-0.5-1.6-0.7-2.3-0.7c-1.4,0-2.1,0.6-2.1,1.3c0,2.1,5.1,0.8,5.1,3.9
                c0,1.3-1,2.4-3.3,2.4C242.5,447.9,241.3,447.5,240.6,446.9z"/>
                <path fill="#4D7199" d="M251.6,444h3.3v1h-3.3V444z" />
                <path fill="#4D7199" d="M266.2,446.8v1.1H260v-8.5h6v1.1h-4.8v2.6h4.2v1h-4.2v2.7L266.2,446.8L266.2,446.8z" />
                <path fill="#4D7199" d="M273.4,447.8l-2.5-3.5l-2.5,3.5H267l3.2-4.3l-3-4.1h1.4l2.4,3.3l2.3-3.3h1.3l-3,4.1l3.2,4.4h-1.4V447.8z"
                />
                <path fill="#4D7199" d="M282.7,442.3c0,1.8-1.3,3-3.5,3h-2.1v2.6h-1.2v-8.5h3.3C281.4,439.4,282.7,440.5,282.7,442.3z
                M281.5,442.3c0-1.2-0.8-1.9-2.4-1.9H277v3.8h2.1C280.7,444.2,281.5,443.5,281.5,442.3z"/>
                <path fill="#4D7199" d="M290.2,447.8l-1.8-2.6c-0.2,0-0.4,0-0.5,0h-2.1v2.6h-1.2v-8.5h3.3c2.2,0,3.5,1.1,3.5,3
                c0,1.3-0.7,2.3-1.9,2.7l2,2.8H290.2z M290.1,442.3c0-1.2-0.8-1.9-2.4-1.9h-2.1v3.8h2.1C289.3,444.2,290.1,443.5,290.1,442.3z"/>
                <path fill="#4D7199" d="M299.4,446.8v1.1h-6.1v-8.5h6v1.1h-4.8v2.6h4.2v1h-4.2v2.7L299.4,446.8L299.4,446.8z" />
                <path fill="#4D7199" d="M300.6,446.9l0.4-0.9c0.6,0.6,1.7,1,2.8,1c1.5,0,2.1-0.6,2.1-1.3c0-2.1-5.1-0.8-5.1-3.9
                c0-1.3,1-2.4,3.2-2.4c1,0,2,0.3,2.7,0.8l-0.4,1c-0.7-0.5-1.6-0.7-2.3-0.7c-1.4,0-2.1,0.6-2.1,1.3c0,2.1,5.1,0.8,5.1,3.9
                c0,1.3-1,2.4-3.3,2.4C302.6,447.9,301.3,447.5,300.6,446.9z"/>
                <path fill="#4D7199" d="M308.1,446.9l0.4-0.9c0.6,0.6,1.7,1,2.8,1c1.5,0,2.1-0.6,2.1-1.3c0-2.1-5.1-0.8-5.1-3.9
                c0-1.3,1-2.4,3.2-2.4c1,0,2,0.3,2.7,0.8l-0.4,1c-0.7-0.5-1.6-0.7-2.3-0.7c-1.4,0-2.1,0.6-2.1,1.3c0,2.1,5.1,0.8,5.1,3.9
                c0,1.3-1,2.4-3.3,2.4C310.1,447.9,308.8,447.5,308.1,446.9z"/>
                <path fill="#4D7199" d="M319.1,444h3.3v1h-3.3V444z" />
                <path fill="#4D7199" d="M334.4,442.3c0,1.8-1.3,3-3.5,3h-2.1v2.6h-1.2v-8.5h3.3C333.1,439.4,334.4,440.5,334.4,442.3z
                M333.2,442.3c0-1.2-0.8-1.9-2.4-1.9h-2.1v3.8h2.1C332.4,444.2,333.2,443.5,333.2,442.3z"/>
                <path fill="#4D7199" d="M335.5,446.9l0.4-0.9c0.6,0.6,1.7,1,2.8,1c1.5,0,2.1-0.6,2.1-1.3c0-2.1-5.1-0.8-5.1-3.9
                c0-1.3,1-2.4,3.2-2.4c1,0,2,0.3,2.7,0.8l-0.4,1c-0.7-0.5-1.6-0.7-2.3-0.7c-1.4,0-2.1,0.6-2.1,1.3c0,2.1,5.1,0.8,5.1,3.9
                c0,1.3-1,2.4-3.3,2.4C337.5,447.9,336.2,447.5,335.5,446.9z"/>
                <path fill="#4D7199" d="M352.5,448.6c-0.5,0.6-1.3,1-2.1,1c-1.1,0-1.9-0.5-3.1-1.7c-2.4-0.1-4.2-1.9-4.2-4.3
                c0-2.5,1.9-4.3,4.5-4.3s4.5,1.8,4.5,4.3c0,2.1-1.4,3.7-3.3,4.2c0.6,0.6,1.1,0.8,1.6,0.8c0.6,0,1.1-0.2,1.5-0.7L352.5,448.6z
                M347.6,446.9c1.9,0,3.3-1.4,3.3-3.3s-1.4-3.3-3.3-3.3s-3.3,1.4-3.3,3.3S345.7,446.9,347.6,446.9z"/>
                <path fill="#4D7199" d="M354,439.4h1.2v7.4h4.6v1.1H354V439.4z" />
                <path fill="#4D7199" d="M363.8,444h3.3v1h-3.3V444z" />
                <path fill="#4D7199" d="M371.6,443.6c0-2.5,1.9-4.3,4.5-4.3c1.3,0,2.4,0.4,3.2,1.3l-0.8,0.8c-0.7-0.7-1.5-1-2.4-1
                c-1.9,0-3.3,1.4-3.3,3.3s1.4,3.3,3.3,3.3c0.9,0,1.7-0.3,2.4-1l0.8,0.8c-0.8,0.9-1.9,1.3-3.2,1.3
                C373.5,447.9,371.6,446.1,371.6,443.6z"/>
                <path fill="#4D7199" d="M380.9,444.2v-4.8h1.2v4.8c0,1.9,0.9,2.7,2.4,2.7s2.4-0.8,2.4-2.7v-4.8h1.2v4.8c0,2.5-1.3,3.8-3.6,3.8
                C382.3,447.9,380.9,446.7,380.9,444.2z"/>
                <path fill="#4D7199" d="M389.8,446.9l0.4-0.9c0.6,0.6,1.7,1,2.8,1c1.5,0,2.1-0.6,2.1-1.3c0-2.1-5.1-0.8-5.1-3.9
                c0-1.3,1-2.4,3.2-2.4c1,0,2,0.3,2.7,0.8l-0.4,1c-0.7-0.5-1.6-0.7-2.3-0.7c-1.4,0-2.1,0.6-2.1,1.3c0,2.1,5.1,0.8,5.1,3.9
                c0,1.3-1,2.4-3.3,2.4C391.8,447.9,390.5,447.5,389.8,446.9z"/>
                <path fill="#4D7199" d="M399.7,440.4h-2.9v-1.1h7v1.1h-2.9v7.4h-1.2V440.4z" />
                <path fill="#4D7199" d="M404.3,443.6c0-2.5,1.9-4.3,4.5-4.3s4.5,1.8,4.5,4.3s-1.9,4.3-4.5,4.3
                C406.2,447.9,404.3,446.1,404.3,443.6z M412.1,443.6c0-1.9-1.4-3.3-3.3-3.3s-3.3,1.4-3.3,3.3s1.4,3.3,3.3,3.3
                S412.1,445.5,412.1,443.6z"/>
                <path fill="#4D7199" d="M423,447.8v-6.2l-3.1,5.1h-0.6l-3.1-5.1v6.1H415v-8.5h1l3.5,6l3.5-6h1v8.5h-1V447.8z" />
                <path fill="#4D7199" d="M429.2,446.9l0.4-0.9c0.6,0.6,1.7,1,2.8,1c1.5,0,2.1-0.6,2.1-1.3c0-2.1-5.1-0.8-5.1-3.9
                c0-1.3,1-2.4,3.2-2.4c1,0,2,0.3,2.7,0.8l-0.4,1c-0.7-0.5-1.6-0.7-2.3-0.7c-1.4,0-2.1,0.6-2.1,1.3c0,2.1,5.1,0.8,5.1,3.9
                c0,1.3-1,2.4-3.3,2.4C431.1,447.9,429.8,447.5,429.2,446.9z"/>
                <path fill="#4D7199" d="M436.7,443.6c0-2.5,1.9-4.3,4.5-4.3s4.5,1.8,4.5,4.3s-1.9,4.3-4.5,4.3
                C438.7,447.9,436.7,446.1,436.7,443.6z M444.5,443.6c0-1.9-1.4-3.3-3.3-3.3s-3.3,1.4-3.3,3.3s1.4,3.3,3.3,3.3
                S444.5,445.5,444.5,443.6z"/>
                <path fill="#4D7199" d="M447.6,439.4h1.2v7.4h4.6v1.1h-5.8L447.6,439.4L447.6,439.4z" />
                <path fill="#4D7199" d="M454.6,444.2v-4.8h1.2v4.8c0,1.9,0.9,2.7,2.4,2.7s2.4-0.8,2.4-2.7v-4.8h1.2v4.8c0,2.5-1.3,3.8-3.6,3.8
                C455.9,447.9,454.6,446.7,454.6,444.2z"/>
                <path fill="#4D7199" d="M465.7,440.4h-2.9v-1.1h7v1.1h-2.9v7.4h-1.2V440.4z" />
                <path fill="#4D7199" d="M471.2,439.4h1.2v8.5h-1.2V439.4z" />
                <path fill="#4D7199" d="M474.2,443.6c0-2.5,1.9-4.3,4.5-4.3s4.5,1.8,4.5,4.3s-1.9,4.3-4.5,4.3S474.2,446.1,474.2,443.6z
                M482,443.6c0-1.9-1.4-3.3-3.3-3.3s-3.3,1.4-3.3,3.3s1.4,3.3,3.3,3.3S482,445.5,482,443.6z"/>
                <path fill="#4D7199" d="M492.3,439.4v8.5h-1l-5.1-6.3v6.3H485v-8.5h1l5.1,6.3v-6.3H492.3z" />
                <path fill="#4D7199" d="M494.1,446.9l0.4-0.9c0.6,0.6,1.7,1,2.8,1c1.5,0,2.1-0.6,2.1-1.3c0-2.1-5.1-0.8-5.1-3.9
                c0-1.3,1-2.4,3.2-2.4c1,0,2,0.3,2.7,0.8l-0.4,1c-0.7-0.5-1.6-0.7-2.3-0.7c-1.4,0-2.1,0.6-2.1,1.3c0,2.1,5.1,0.8,5.1,3.9
                c0,1.3-1,2.4-3.3,2.4C496.1,447.9,494.8,447.5,494.1,446.9z"/>
            </g>
            <g >
                <path fill="#4D7199" d="M821.8,376.9h4.9c3.7,0,6.2,2.3,6.2,5.6c0,3.4-2.5,5.6-6.2,5.6h-4.9V376.9z M826.6,386.4
                c2.5,0,4.1-1.5,4.1-3.9c0-2.3-1.6-3.9-4.1-3.9h-2.7v7.7L826.6,386.4L826.6,386.4z"/>
                <path fill="#4D7199" d="M844.6,386.4v1.8h-8.5v-11.3h8.2v1.8h-6.1v2.9h5.5v1.7h-5.5v3.1H844.6z" />
                <path fill="#4D7199" d="M846.9,387l0.7-1.6c0.9,0.7,2.3,1.2,3.7,1.2c1.8,0,2.5-0.6,2.5-1.5c0-2.4-6.7-0.8-6.7-5
                c0-1.8,1.5-3.4,4.5-3.4c1.4,0,2.8,0.4,3.8,1l-0.7,1.6c-1-0.6-2.1-0.9-3.1-0.9c-1.7,0-2.5,0.7-2.5,1.5c0,2.4,6.7,0.8,6.7,5
                c0,1.8-1.5,3.4-4.6,3.4C849.6,388.3,847.9,387.8,846.9,387z"/>
                <path fill="#4D7199" d="M859,376.9h2.1v11.3H859V376.9z" />
                <path fill="#4D7199" d="M872.9,382.4h2v4.5c-1.2,1-2.8,1.4-4.4,1.4c-3.5,0-6.1-2.4-6.1-5.8s2.6-5.8,6.1-5.8c1.9,0,3.5,0.6,4.5,1.8
                l-1.3,1.3c-0.9-0.9-1.9-1.3-3.1-1.3c-2.4,0-4.1,1.6-4.1,4c0,2.3,1.7,4,4.1,4c0.8,0,1.6-0.2,2.3-0.6V382.4z"/>
                <path fill="#4D7199" d="M888.6,376.9v11.3h-1.7l-6.2-7.6v7.6h-2.1v-11.3h1.7l6.2,7.6v-7.6H888.6z" />
                <path fill="#4D7199" d="M898.1,376.2h1.9v15.1h-1.9V376.2z" />
                <path fill="#4D7199" d="M903.9,376.2h1.9v15.1h-1.9V376.2z" />
                <path fill="#4D7199" d="M915.3,376.9h4.9c3.7,0,6.2,2.3,6.2,5.6c0,3.4-2.5,5.6-6.2,5.6h-4.9V376.9z M920.1,386.4
                c2.5,0,4.1-1.5,4.1-3.9c0-2.3-1.6-3.9-4.1-3.9h-2.7v7.7L920.1,386.4L920.1,386.4z"/>
                <path fill="#4D7199" d="M938,386.4v1.8h-8.5v-11.3h8.2v1.8h-6.1v2.9h5.5v1.7h-5.5v3.1H938z" />
                <path fill="#4D7199" d="M951.5,376.9l-4.9,11.3h-2.1l-4.9-11.3h2.3l3.8,8.7l3.8-8.7H951.5z" />
                <path fill="#4D7199" d="M962.2,386.4v1.8h-8.5v-11.3h8.2v1.8h-6.1v2.9h5.5v1.7h-5.5v3.1H962.2z" />
                <path fill="#4D7199" d="M965.5,376.9h2.1v9.5h5.9v1.8h-8V376.9z" />
                <path fill="#4D7199" d="M975.1,382.5c0-3.3,2.6-5.8,6.1-5.8s6.1,2.5,6.1,5.8c0,3.4-2.6,5.8-6.1,5.8S975.1,385.8,975.1,382.5z
                M985.2,382.5c0-2.3-1.7-4-4-4s-4,1.7-4,4s1.7,4,4,4S985.2,384.8,985.2,382.5z"/>
                <path fill="#4D7199" d="M999.9,380.9c0,2.5-1.8,4-4.8,4h-2.5v3.3h-2.1v-11.3h4.6C998.1,376.9,999.9,378.4,999.9,380.9z
                M997.8,380.9c0-1.4-1-2.2-2.8-2.2h-2.5v4.5h2.5C996.9,383.1,997.8,382.3,997.8,380.9z"/>
                <path fill="#4D7199" d="M1013.4,388.1v-7.5l-3.7,6.2h-0.9l-3.7-6.1v7.4h-2v-11.3h1.7l4.5,7.5l4.4-7.5h1.7v11.3H1013.4z" />
                <path fill="#4D7199" d="M1027.9,386.4v1.8h-8.5v-11.3h8.2v1.8h-6.1v2.9h5.5v1.7h-5.5v3.1H1027.9z" />
                <path fill="#4D7199" d="M1041.2,376.9v11.3h-1.7l-6.2-7.6v7.6h-2.1v-11.3h1.7l6.2,7.6v-7.6H1041.2z" />
                <path fill="#4D7199" d="M1047.5,378.6h-3.7v-1.8h9.6v1.8h-3.7v9.5h-2.1v-9.5H1047.5z" />
                <path fill="#4D7199" d="M1061.4,376.2h1.9v15.1h-1.9V376.2z" />
                <path fill="#4D7199" d="M1067.2,376.2h1.9v15.1h-1.9V376.2z" />
            </g>
        </g>
    )
}