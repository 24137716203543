import { useEffect, useState } from 'react';

export const useDynamicGradient = (props) => {

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {

        if (props.elem && !initialized) {

            const dynamicGradient = () => {
                const scrollMax = () => Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;
                const percent = window.scrollY / scrollMax();
                const color = 114 * percent;
                props.elem.style.background = `linear-gradient(90deg, rgba(46, ${color}, ${color},1) 2%, rgba(18,28,37,1) 50%, rgba(0,0,0,1) 100%)`;
            }

            props.elem.style.background = `linear-gradient(90deg, rgba(46,0,0,1) 2%, rgba(18,28,37,1) 50%, rgba(0,0,0,1) 100%)`;

            window.addEventListener('scroll', dynamicGradient);

            setInitialized(true);

            return () => window.removeEventListener('scroll', dynamicGradient);
        }
    }, [props.elem, initialized])

}
