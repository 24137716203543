import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import WebFont from 'webfontloader';
import { SVGBackground } from './components/SVG/SVGBackground';
import { Menu } from './components/Menu/Menu';
import { useDynamicGradient } from './utils/useDynamicGradient';
import { AnimateFade } from './components/Animate/AnimateFade';
import { animate } from './config/Animate.config';
import { AboutMeHeader, WorkHeader, ContactHeader } from './components/SectionHeaders';
import { AboutMe } from './components/Sections';
import { Work } from './components/Sections/Work/Work';
import { Contact } from './components/Sections/Contact/Contact';


function App() {

  const [fontLoaded, setFontLoaded] = useState(false);
  const wrapperRef = useRef(null);
  const [bgHeight, setBGHeight] = useState(0);

  if (!fontLoaded) {
    WebFont.load({
      custom: {
        families: ['Montserrat'],
        urls: ['https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap']
      },
      active: () => setFontLoaded(true),
      inactive: () => console.warn('Cannot load font')
    })
  }

  useDynamicGradient({ elem: document.body })

  useEffect(() => {

    if (wrapperRef.current) {
      setBGHeight(wrapperRef.current.getBoundingClientRect().height)
      window.addEventListener('resize', () => setBGHeight(wrapperRef.current.getBoundingClientRect().height))
    }

  }, [wrapperRef, fontLoaded])


  return (
    <div className="App">

      {fontLoaded &&
        <div id='home' className='wrapper'>
          <Menu />

          <div ref={wrapperRef} className='layout-wrapper'>

            <div className='layout'>

              <section id='blank' className='inner'></section>
              <section id='about' className='inner'>
                <AnimateFade options={animate.SectionHeaders} ><AboutMeHeader /></AnimateFade>
                <AnimateFade options={animate.AboutMe} > <AboutMe /> </AnimateFade>
              </section>
              <section id='work' className='inner'>
                <AnimateFade options={animate.SectionHeaders} ><WorkHeader /></AnimateFade>
                <AnimateFade options={animate.Work} ><Work /></AnimateFade>
              </section>
              <section id='contact' className='inner'>
                <AnimateFade options={animate.SectionHeaders} ><ContactHeader /></AnimateFade>
                <AnimateFade options={animate.Contact} ><Contact /></AnimateFade>
              </section>
            </div>
          </div>
        </div>
      }

      {bgHeight && <div className="background-wrapper" style={{ height: bgHeight + 'px', zIndex: -1 }}><AnimateFade><SVGBackground /></AnimateFade></div>}
    </div>
  );
}

export default App;
