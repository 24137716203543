import { useEffect, useState, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

let opacityTimeout = null;

export const useScrollTriggers = (props) => {

    const { menuConfig, onNewMarkerPosition } = props;
    const [initialized, setInitialized] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const prevActive = useRef(null)

    // Listen to page position and set the menuMarker based on scrollTriggers set by menuConfig data
    useEffect(() => {
        if (!initialized) {
            const scrollMax = () => Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;

            menuConfig.forEach((item, i) => {

                if (i === 0) {
                    ScrollTrigger.create({
                        start: -1,
                        end: 'top 15%',
                        onEnter: () => { setActiveItem(document.getElementById(item.menuElem)) }
                    })
                }

                else {
                    ScrollTrigger.create({
                        trigger: item.anchor,
                        endTrigger: menuConfig[i + 1] ? menuConfig[i + 1].anchor : null,
                        start: i === 0 ? '0px' : 'top 25%',
                        end: 'top 55%',
                        onEnter: () => setActiveItem(document.getElementById(item.menuElem)),
                        onLeaveBack: () => setActiveItem(document.getElementById(menuConfig[i - 1].menuElem))
                    })
                }

                if (i === menuConfig.length - 1) {
                    ScrollTrigger.create({
                        start: scrollMax() - 20,
                        end: 'top 55%',
                        onEnter: () => { setActiveItem(document.getElementById(item.menuElem)) },
                        onLeaveBack: () => setActiveItem(document.getElementById(menuConfig[i - 1].menuElem))
                    })
                }
            })

            setInitialized(true);
        }

    }, [initialized, menuConfig])

    //Trigger the onNewMarkerPosition function when a new activeItem is set from the scrollTriggers --> sets the menuMarker size and position
    useEffect(() => {

        if (activeItem && prevActive.current !== activeItem) {
            prevActive.current = activeItem;
            const bounds = activeItem.getBoundingClientRect();

            onNewMarkerPosition({
                top: bounds.top - 1,
                left: bounds.left - 1,
                width: bounds.width,
                height: bounds.height - 2
            })

            const onResize = () => {
                const menuMarker = document.getElementById('menu-marker');
                menuMarker.style.opacity = 0;

                const bounds = activeItem.getBoundingClientRect();

                onNewMarkerPosition({
                    top: bounds.top - 1,
                    left: bounds.left - 1,
                    width: bounds.width,
                    height: bounds.height - 2
                })

                clearTimeout(opacityTimeout);
                opacityTimeout = setTimeout(() => menuMarker.style.opacity = 1, 100)
            }

            //onResize relies on current DOM properties, must be re-instantiated after every update on markerPosition or activeItem
            window.removeEventListener('resize', onResize);
            window.addEventListener('resize', onResize);
        }

    }, [activeItem, onNewMarkerPosition])

}