import React from 'react';


export const ContactButton = (props) => {

    const clickHandler = ({ target }) => {
        target.children[0].style.animation = 'circleGrow 0.25s ease-out';
        setTimeout(() => {
            target.children[0].style.animation = '';
            window.open(props.linkurl, '_blank');
        }, 300);

    }

    return (
        <div
            ref={props.animateRef}
            className='contact-btn-wrapper'
            onClick={clickHandler}
        >
            <div
                className='contact-icon'
                style={{ backgroundImage: `url(${props.backgroundImage})` }}
            ><div className='click-response'></div></div>

            <div className='contact-url'>{props.url}</div>
        </div>
    )
} 