export const menuConfig = [{
    type: "anchor",
    menuElem: "menu_home",
    menuText: "HOME",
    anchor: "#home"
},
{
    type: "anchor",
    menuElem: "menu_about",
    menuText: "ABOUT",
    anchor: "#about",
    offset: 50
},
{
    type: "anchor",
    menuElem: "menu_work",
    menuText: "WORK",
    anchor: "#work",
    offset: 50
},
{
    type: "anchor",
    menuElem: "menu_contact",
    menuText: "CONTACT",
    anchor: "#contact",
    offset: 50

}]