import React from 'react'
import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);


export const AnchorLink = (props) => {

    const scroll = (e) => {
        e.preventDefault()
        let offset = () => 0
        if (typeof props.offset !== 'undefined') {
            if (!!(props.offset && props.offset.constructor && props.offset.apply)) {
                offset = props.offset
            } else {
                offset = () => parseInt(props.offset)
            }
        }
        const id = e.currentTarget.getAttribute('href').slice(1)
        const $anchor = document.getElementById(id);
        const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
        gsap.to(window, { scrollTo: offsetTop - offset() })

        if (props.onClick) { props.onClick(e) }
    }
    return (
        <div className='anchor-link' href={props.href} onClick={scroll} >{props.text || props.href.slice(1)}</div>
    )
}

