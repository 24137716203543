import React from 'react';

export const SVGParralaxLayer2 = (props) => {

    return (
        <g ref={props.animateRef} id="parallax_layer_2">
            <rect x="406" y="3181.8" fill="none" stroke="#364B67" strokeMiterlimit="10" width="143" height="143" />
            <rect x="179.4" y="2442.3" fill="none" stroke="#364B67" strokeMiterlimit="10" width="273" height="273" />
            <rect x="454.7" y="458.7" fill="none" stroke="#364B67" strokeMiterlimit="10" width="408.6" height="408.6" />
            <rect x="570.4" y="2891.3" fill="none" stroke="#364B67" strokeMiterlimit="10" width="182" height="182" />
            <rect x="92.4" y="1108" fill="none" stroke="#364B67" strokeMiterlimit="10" width="408.6" height="408.6" />
            <rect x="887.4" y="1356.7" fill="none" stroke="#364B67" strokeMiterlimit="10" width="408.6" height="408.6" />
            <rect x="820.6" y="2251.7" fill="none" stroke="#364B67" strokeMiterlimit="10" width="408.6" height="408.6" />
            <rect x="1241" y="4206.8" fill="none" stroke="#364B67" strokeMiterlimit="10" width="313" height="313" />
            <rect x="1020.7" y="6776" fill="none" stroke="#364B67" strokeMiterlimit="10" width="143" height="143" />
            <rect x="-125.9" y="5812.6" fill="none" stroke="#364B67" strokeMiterlimit="10" width="273" height="273" />
            <rect x="149.4" y="3829" fill="none" stroke="#364B67" strokeMiterlimit="10" width="408.6" height="408.6" />
            <rect x="174.1" y="8061.6" fill="none" stroke="#364B67" strokeMiterlimit="10" width="182" height="182" />
            <rect x="127.1" y="6956" fill="none" stroke="#364B67" strokeMiterlimit="10" width="332" height="332" />
            <rect x="392" y="4936" fill="none" stroke="#364B67" strokeMiterlimit="10" width="332" height="332" />
            <rect x="-5.3" y="8780" fill="none" stroke="#364B67" strokeMiterlimit="10" width="408.6" height="408.6" />
            <rect x="755.1" y="9335.4" fill="none" stroke="#364B67" strokeMiterlimit="10" width="408.6" height="408.6" />
            <rect x="935.7" y="7577" fill="none" stroke="#364B67" strokeMiterlimit="10" width="313" height="313" />
        </g>
    )
}