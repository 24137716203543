import React from 'react';

export const ContactHeader = (props) => {
    return (
        <div id='contact_header' ref={props.animateRef} >
            <div className='section-header right'>
                <div className='section-header-line red left' ></div>
                <svg viewBox='0 0 436.4 100.3' height={'100%'} >
                    <text
                        transform="matrix(0.89 0 0 1 1.5001 75.6192)"
                        fill="none"
                        stroke="#F2665F"
                        strokeWidth={3}
                        strokeMiterlimit={10}
                        fontFamily="Montserrat"
                        fontWeight="bold"
                        fontSize={87.2}
                        letterSpacing={5.6}>CONTACT</text>
                </svg>
            </div>
        </div>

    )
}