import React from 'react';
import './Contact.css';
import { ContactButton } from './ContactButton';

export const Contact = (props) => {

    return (
        <div ref={props.animateRef} className='contact-wrapper'>

            <ContactButton linkurl={'https://github.com/crispinanderson'} url={'github.com/crispinanderson'} backgroundImage='/public/SVG/github-icon.svg' />
            <ContactButton linkurl={'https://medium.com/@crispin.anderson'} url={'medium.com/@crispin.anderson'} backgroundImage='/public/SVG/medium-icon.svg' />
            <ContactButton linkurl={'mailto:crispin.anderson@gmail.com'} url={'crispin.anderson@gmail.com'} backgroundImage='/public/SVG/email-icon.svg' />

        </div>

    )
}