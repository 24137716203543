import React from 'react';

export const SVGParralaxLayer1 = (props) => {

    return (
        <g ref={props.animateRef} id="parallax_layer_1">
            <rect x="360.4" y="1469.3" fill="none" stroke="#364B67" strokeMiterlimit="10" width="194" height="194" />
            <rect x="127.4" y="3949" fill="none" stroke="#364B67" strokeMiterlimit="10" width="143" height="143" />
            <rect x="1008" y="1906.5" fill="none" stroke="#364B67" strokeMiterlimit="10" width="143" height="143" />
            <rect x="43.4" y="987.3" fill="none" stroke="#364B67" strokeMiterlimit="10" width="194" height="194" />
            <rect x="995" y="559.3" fill="none" stroke="#364B67" strokeMiterlimit="10" width="194" height="194" />
            <rect x="148.4" y="1758.3" fill="none" stroke="#364B67" strokeMiterlimit="10" width="194" height="194" />
            <rect x="919.4" y="3219.3" fill="none" stroke="#364B67" strokeMiterlimit="10" width="211" height="211" />
            <rect x="314" y="7480" fill="none" stroke="#364B67" strokeMiterlimit="10" width="194" height="194" />
            <rect x="-16" y="7744.9" fill="none" stroke="#364B67" strokeMiterlimit="10" width="143" height="143" />
            <rect x="864.6" y="5702.4" fill="none" stroke="#364B67" strokeMiterlimit="10" width="143" height="143" />
            <rect x="-100" y="4783.1" fill="none" stroke="#364B67" strokeMiterlimit="10" width="194" height="194" />
            <rect x="851.6" y="4355.1" fill="none" stroke="#364B67" strokeMiterlimit="10" width="194" height="194" />
            <rect x="1112" y="8758.2" fill="none" stroke="#364B67" strokeMiterlimit="10" width="194" height="194" />
            <rect x="776" y="7015.1" fill="none" stroke="#364B67" strokeMiterlimit="10" width="211" height="211" />
        </g>
    )
}