import { useEffect } from 'react';
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger)



const defaults = {
    duration: 0.5,
    ease: 'ease-out',
    from: 0,
    to: 1,
    delay: 0,
    event: "load",
    triggerElem: window,
    offset: 0
}

export const useFade = (props) => {

    const options = { ...defaults, ...props.options };
    const { from, to, event, triggerElem, offset, delay, duration, ease, stagger } = options;

    useEffect(() => {
        const elem = props.animateRef.current;

        if (elem && triggerElem) {

            if (event === 'load') {
                gsap.to(elem, {
                    opacity: to,
                    duration: duration,
                    delay: delay,
                    ease
                })
            }
            if (event === 'in-view') {

                if (stagger || stagger === 0) {

                    gsap.fromTo(Array.from(elem.children), { opacity: from }, {
                        opacity: to,
                        duration: duration,
                        delay: delay,
                        ease,
                        stagger: stagger,
                        scrollTrigger: {
                            trigger: elem,
                            start: '0px 90%',
                            toggleActions: 'restart none none reverse',

                        },

                    })
                }

                else {
                    elem.style.opacity = from;
                    gsap.fromTo(elem,
                        { opacity: from },
                        {
                            opacity: to,
                            duration: duration,
                            delay: delay,
                            ease,
                            scrollTrigger: {
                                trigger: elem,
                                start: '0px 90%',
                                toggleActions: 'restart none none reverse',

                            },

                        })
                }


            }

        }

    }, [props.animateRef, triggerElem, from, to, event, offset, delay, duration, ease, stagger])
}